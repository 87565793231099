<template>
  <div id="app" v-cloak>
    <!-- <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div> -->

    <keep-alive>
    <router-view  v-if="$route.meta.keepneed&&isRouterAlive"  @suspends="getChild"  @changeCatgory="getPrice" />
    </keep-alive>
    <router-view  v-if="isRouterAlive?!$route.meta.keepneed:false"  @suspends="getChild"  @changeCatgory="getPrice" />

        <!-- 登录页不显示 -->
<!--      <sevrice v-if="!$route.meta.sevrice  && processEnv "/>-->
<!--      <suspends v-if="!$route.meta.suspends && suspends"  :initPriceName="initPriceName"/>-->
       <sevrice  v-if="isRouterAlive" />
    <footerbox class="footer"></footerbox>
    <!-- <home1></home1> -->
  </div>
</template>
<script>
import footerbox from "@/components/footer";
import sevrice from '@/components/sevrice.vue'
import suspends from '@/components/suspends.vue'
export default {

  provide() {
    return {
      reload: this.reload,
      sevriceShow:true,
    };
  },
  data() {
    return {
      isRouterAlive: true,
      suspends:Boolean,
      initPriceName:  this.$store.state.infoPriceName || 'LON_Au',
      processEnv:true,
    };
  },
  components: {
    footerbox,
    sevrice,
    suspends
    // home1
  },

  methods: {
    getPrice(item){
      this.$store.dispatch('getchangeInfoPriceName',item)
      this.initPriceName = item
    },
    getChild(v){
      this.suspends = v
    },
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(function() {
        this.isRouterAlive = true;
      });
    }
  },
  created(){
    process.env.VUE_APP_SHOW_SERVICE == 'false'?this.processEnv = false:this.processEnv = true

  }
};
</script>
<style lang="scss">
   [v-cloak]{
          display:none;
  }
.van-dialog__confirm {
  height: 80px !important;
}
.el-tooltip__popper.is-dark {
  color: #fff !important;
  // background: #fff !important;
  padding: 12px !important;
  font-size: 28px;
}
.el-form-item__error {
  font-size: 26px !important;
}
.van-dialog {
  border-radius: 12px !important;
}
.van-dialog__footer--buttons {
  border-top: 1px solid #cccccc;
}
.van-dialog {
  padding: 10px 15px;
}
.van-dialog__cancel {
  border-right: 1px solid #cccccc !important ;
}
.van-dialog__confirm,
.van-dialog__confirm:active {
  color: #FF2D2E !important;
}
.el-checkbox__input.is-checked .el-checkbox__inner {
  background: #FF2D2E !important;
  border-color: #FF2D2E !important;
}
.el-checkbox__inner:hover {
  border-color: #FF2D2E !important;
}

.el-date-range-picker {
  width: 690px !important;
  font-size: 24px !important;
}
   .el-picker-panel__icon-btn {
     font-size: 24px !important;
   }
   .el-date-range-picker__header div {
     font-size: 24px !important;
     padding-top: 6px;
   }
   .el-date-table {
     font-size: 24px !important;
   }
   .el-date-table td.end-date span {
     background-color: #ffffff !important;
     color: #FF2D2E;
   }
   .el-date-table td.start-date span {
     background-color: #ffffff !important;
     color: #FF2D2E;
   }
   .el-date-table td.today span {
     background-color: #ffffff !important;
     color: #FF2D2E !important;
   }
   .el-date-table td.today.end-date span {
     background-color: #ffffff !important;
     color: #FF2D2E !important;
   }

   .el-date-table td.today.start-date span {
     background-color: #ffffff !important;
     color: #FF2D2E !important;
   }
   .el-select-dropdown__wrap {
     font-size: 28px;
     ul li {
       margin-left: 0 !important;
       margin-bottom: 0 !important;
       padding: 0 2vw 2vw !important;
       border-bottom: none !important;
       font-size: 24px !important;
     }
   }

#app {
  // width: 750px;
  margin: 0 auto;
  font-family: PingFang SC-Medium, PingFang SC !important;

}
#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
// 修改客服聊天界面的高度
#YSF-PANEL-MOBILE{
  #YSF-IFRAME-LAYER.ysf-chat-mobile-layeropen{
    height: 100% !important;

     }
}
</style>
