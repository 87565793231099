var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", {
      ref: "qiyu",
      staticClass: "qiyukefu",
      style: { left: _vm.x + "px", top: _vm.y + "px" },
      on: {
        click: _vm.openWx,
        mousedown: function($event) {
          $event.stopPropagation()
          return _vm.dragStartHandler($event)
        },
        touchstart: function($event) {
          $event.stopPropagation()
          return _vm.dragStartHandler($event)
        },
        mousemove: function($event) {
          $event.stopPropagation()
          return _vm.draggingHandler($event)
        },
        touchmove: function($event) {
          $event.stopPropagation()
          return _vm.draggingHandler($event)
        },
        mouseup: function($event) {
          $event.stopPropagation()
          return _vm.dragEndHandler($event)
        },
        touchend: function($event) {
          $event.stopPropagation()
          return _vm.dragEndHandler($event)
        }
      }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }